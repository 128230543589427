exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-about-us-template-js": () => import("./../../../src/templates/AboutUsTemplate.js" /* webpackChunkName: "component---src-templates-about-us-template-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/BlogListTemplate.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/BlogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/CategoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-event-future-past-list-template-js": () => import("./../../../src/templates/EventFuturePastListTemplate.js" /* webpackChunkName: "component---src-templates-event-future-past-list-template-js" */),
  "component---src-templates-event-list-template-js": () => import("./../../../src/templates/EventListTemplate.js" /* webpackChunkName: "component---src-templates-event-list-template-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/EventTemplate.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-faq-template-js": () => import("./../../../src/templates/FaqTemplate.js" /* webpackChunkName: "component---src-templates-faq-template-js" */),
  "component---src-templates-full-category-template-js": () => import("./../../../src/templates/FullCategoryTemplate.js" /* webpackChunkName: "component---src-templates-full-category-template-js" */),
  "component---src-templates-i-challenge-company-template-js": () => import("./../../../src/templates/IChallengeCompanyTemplate.js" /* webpackChunkName: "component---src-templates-i-challenge-company-template-js" */),
  "component---src-templates-innovation-challenge-template-js": () => import("./../../../src/templates/InnovationChallengeTemplate.js" /* webpackChunkName: "component---src-templates-innovation-challenge-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-person-template-js": () => import("./../../../src/templates/PersonTemplate.js" /* webpackChunkName: "component---src-templates-person-template-js" */),
  "component---src-templates-podcast-template-js": () => import("./../../../src/templates/PodcastTemplate.js" /* webpackChunkName: "component---src-templates-podcast-template-js" */),
  "component---src-templates-research-category-template-js": () => import("./../../../src/templates/ResearchCategoryTemplate.js" /* webpackChunkName: "component---src-templates-research-category-template-js" */),
  "component---src-templates-research-list-template-js": () => import("./../../../src/templates/ResearchListTemplate.js" /* webpackChunkName: "component---src-templates-research-list-template-js" */),
  "component---src-templates-research-template-js": () => import("./../../../src/templates/ResearchTemplate.js" /* webpackChunkName: "component---src-templates-research-template-js" */),
  "component---src-templates-webinar-list-template-js": () => import("./../../../src/templates/WebinarListTemplate.js" /* webpackChunkName: "component---src-templates-webinar-list-template-js" */),
  "component---src-templates-webinar-template-js": () => import("./../../../src/templates/WebinarTemplate.js" /* webpackChunkName: "component---src-templates-webinar-template-js" */)
}

