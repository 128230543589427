import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"
import * as prismic from "@prismicio/client"

export const repositoryName = process.env.GATSBY_PRISMIC_REPO_NAME

const prismicClient = prismic.createClient(repositoryName, {
  accessToken: process.env.GATSBY_PRISMIC_CUSTOM_TYPES_API_TOKEN,
})

export const client = new ApolloClient({
  link: new HttpLink({
    uri: prismic.getGraphQLEndpoint(repositoryName),
    fetch: prismicClient.graphQLFetch,
    useGETForQueries: true,
  }),
  cache: new InMemoryCache(),
})
