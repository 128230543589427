import React from "react"
import { ApolloProvider } from "@apollo/client"
import { client } from "./src/apollo/apolloProvider"

export const wrapPageElement = ({ element, props }) => {
  return (
    <ApolloProvider client={client} {...props}>
      {element}
    </ApolloProvider>
  )
}
